<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col"></div>
            <div class="col-auto">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                :aria-label="$t(`addNewContactsModal.close`)"
              ></button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <BaseIcon name="puzzle" class="modal-icon" />
            </div>
            <div class="col-12 text-center">
              <h1 class="bold-19">
                {{ $t("addNewIntegration.addNewIntegrations") }}
              </h1>
              <p class="regular-14">
                {{ $t("addNewIntegration.addANewPluginListOrARepository") }}
              </p>
            </div>
          </div>
          <div
            class="row mt-4 align-items-center mx-3 custom-input whitebg-input"
          >
            <div class="col-12">
              <label class="form-label bold-12 text-gray">
                {{ $t("addNewIntegration.listOrARepositoryUrl") }}
              </label>
              <input
                type="url"
                v-model="url"
                placeholder="https://"
                class="form-control"
              />
            </div>
            <div class="col-12 mt-2" v-if="branches">
              <v-select
                v-model="branch"
                :options="branches"
                :placeholder="$t(`addNewIntegration.selectABranch`)"
                :clearable="false"
                append-to-body
              />
            </div>
            <div class="col text-gray mt-2">
              <BaseIcon name="box2" />
              <BaseIcon name="github" />
              <BaseIcon name="gitlab" />
              <BaseIcon name="git-fork" />
            </div>
            <div class="col-auto mt-2">
              <button
                type="button"
                class="btn btn-primary"
                v-on:click="check"
                v-if="branches === null"
              >
                {{ $t("addNewIntegration.next") }}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                v-else
                v-on:click="add"
              >
                {{ $t("addNewIntegration.addPlugin") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "../icons/BaseIcon";
import http from "../../modules/http";
const bootstrap = require("bootstrap");

export default {
  name: "AddNewIntegration",
  components: { BaseIcon },
  props: {
    editCategory: Boolean,
    vatRates: Object,
    activeLanguages: Array,
    selectedLanguage: String,
    categories: Array,
  },
  emits: [
    "update:selectedLanguage",
    "update:show",
    "addNewCategory",
    "saveCategory",
  ],
  data() {
    return {
      url: "",
      branch: null,
      branches: null,
    };
  },
  methods: {
    add() {
      http
        .fetch("/integrations/add", { url: this.url, branch: this.branch })
        .then((data) => {
          console.log(data);
          if (data.id !== null) {
            location.reload();
          }
        });
    },
    check() {
      http.fetch("/integrations/check", { url: this.url }).then((data) => {
        console.log(data);
        if (data.branches !== undefined) {
          this.branches = data.branches;
          this.branch = this.branches[0];
        } else {
          this.branches = null;
        }
        if (data.authorize !== undefined) {
          document.location = data.authorize;
        }
      });
    },
    hideModal() {
      this.myModal.hide();
    },
    showModal() {
      this.myModal = new bootstrap.Modal(this.$refs.modal, {
        backdrop: "static",
      });
      this.myModal.show();
    },
  },
};
</script>
