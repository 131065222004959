<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{
              $t(`integrationSettingsModal.settings`, {
                name: modelValue?.name,
              })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-3 mt-2">
              <button
                class="btn w-100 d-block text-start"
                v-on:click="selectedOption = 0"
                :class="{
                  'btn-primary': selectedOption === 0,
                  'btn-simple': selectedOption !== 0,
                }"
              >
                {{ $t("finance.basics") }}
              </button>
              <button
                class="btn w-100 d-block text-start"
                v-on:click="selectedOption = 1"
                :class="{
                  'btn-primary': selectedOption === 1,
                  'btn-simple': selectedOption !== 1,
                }"
                v-if="
                  integration?.appSettings?.findIndex((e) => e.ref_type === 1) >
                  -1
                "
              >
                {{ $t("integrationSettingsModal.companySettings") }}
              </button>
              <button
                class="btn w-100 d-block text-start"
                v-on:click="selectedOption = 2"
                :class="{
                  'btn-primary': selectedOption === 2,
                  'btn-simple': selectedOption !== 2,
                }"
                v-if="
                  integration?.appSettings?.findIndex((e) => e.ref_type === 2) >
                  -1
                "
              >
                {{ $t("integrationSettingsModal.warehouseSettings") }}
              </button>
              <button
                class="btn w-100 d-block text-start"
                v-on:click="selectedOption = 3"
                :class="{
                  'btn-primary': selectedOption === 3,
                  'btn-simple': selectedOption !== 3,
                }"
                v-if="
                  integration?.appSettings?.findIndex((e) => e.ref_type === 3) >
                  -1
                "
              >
                {{ $t("integrationSettingsModal.webshopSettings") }}
              </button>
            </div>
            <div class="col-9 mt-2 text-start custom-input">
              <div class="row row-tabs" v-if="selectedOption === 1">
                <div
                  class="col-auto p-0 m-0"
                  v-for="c in companies"
                  :key="c.id"
                >
                  <button
                    class="btn btn-tab"
                    :class="{ active: c.id === selectedCompany }"
                    v-on:click="changeCompany(c.id)"
                  >
                    <span class="semibold-14">{{ c.name }}</span>
                  </button>
                </div>
              </div>
              <div class="row row-tabs" v-if="selectedOption === 2">
                <div
                  class="col-auto p-0 m-0"
                  v-for="w in warehouses"
                  :key="w.id"
                >
                  <button
                    class="btn btn-tab"
                    :class="{ active: w.id === selectedWarehouse }"
                    v-on:click="changeWarehouse(w.id)"
                  >
                    <span class="semibold-14">{{ w.name }}</span>
                  </button>
                </div>
              </div>
              <div class="row row-tabs" v-if="selectedOption === 3">
                <div class="col-auto p-0 m-0" v-for="w in webshops" :key="w.id">
                  <button
                    class="btn btn-tab"
                    :class="{ active: w.id === selectedWebshop }"
                    v-on:click="changeWebshop(w.id)"
                  >
                    <span class="semibold-14">{{ w.name }}</span>
                  </button>
                </div>
              </div>

              <div class="row mt-3" v-if="integration">
                <div
                  class="col-12 mb-3 whitebg-input"
                  v-for="setting in integration.appSettings.filter(
                    (e) =>
                      e.ref_type === selectedOption ||
                      (e.ref_type === null && selectedOption === 0)
                  )"
                  :key="setting.id"
                >
                  <label class="form-label bold-12 text-gray">
                    {{ setting.name }}
                  </label>
                  <input
                    :type="
                      setting.type < 3
                        ? 'number'
                        : setting.type === 4
                        ? 'date'
                        : 'text'
                    "
                    class="form-control"
                    v-model="
                      appSettings[setting.option_key][
                        selectedOption === 1
                          ? selectedCompany
                          : selectedOption === 2
                          ? selectedWarehouse
                          : selectedOption === 3
                          ? selectedWebshop
                          : 0
                      ]
                    "
                    v-if="setting.type < 5"
                  />
                  <textarea
                    class="form-control"
                    v-model="
                      appSettings[setting.option_key][
                        selectedOption === 1
                          ? selectedCompany
                          : selectedOption === 2
                          ? selectedWarehouse
                          : selectedOption === 3
                          ? selectedWebshop
                          : 0
                      ]
                    "
                    v-if="setting.type == 7"
                  ></textarea>
                  <v-select
                    v-if="setting.type === 6"
                    :options="
                      Object.keys(setting.options?.options).map(function (key) {
                        return {
                          code: key,
                          label: setting.options?.options[key],
                        };
                      })
                    "
                    v-model="
                      appSettings[setting.option_key][
                        selectedOption === 1
                          ? selectedCompany
                          : selectedOption === 2
                          ? selectedWarehouse
                          : selectedOption === 3
                          ? selectedWebshop
                          : 0
                      ]
                    "
                    :reduce="(c) => c.code"
                    :getOptionKey="(c) => c.code"
                    :placeholder="$t(`integrationSettingsModal.selectValue`)"
                    append-to-body
                  />
                  <p class="regular-10 text-gray-dark mt-1">
                    {{ setting.description }}
                  </p>
                </div>
              </div>
              <div class="row mt-3" v-if="selectedOption === 0">
                <div class="col-12" v-if="modelValue?.auth_url">
                  <button class="btn btn-primary" v-on:click="login">
                    {{ $t("integrationSettingsModal.login") }}
                  </button>
                </div>
                <div class="col-3">
                  {{ $t("integrationSettingsModal.version") }}
                </div>
                <div class="col-3">{{ modelValue?.version }}</div>
                <div class="col-2">
                  {{ $t("integrationSettingsModal.release") }}
                </div>
                <div class="col-4">
                  {{ formatDate(modelValue?.release_date) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" v-on:click="save()">
            {{ $t("finance.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import date from "@/modules/date";
import http from "@/modules/http";
import { useStore } from "vuex";

const bootstrap = require("bootstrap");

export default {
  name: "IntegrationSettingsModal",
  props: {
    component: String,
    modelValue: Object,
  },
  data() {
    return {
      store: useStore(),
      myModal: undefined,
      currentTabComponent: undefined,
      modalData: undefined,
      integration: undefined,
      selectedWarehouse: null,
      selectedCompany: null,
      selectedWebshop: null,
      selectedOption: 0,
      companies: [],
      webshops: [],
      appSettings: {},
    };
  },
  watch: {
    modelValue: {
      deep: true,
      handler() {
        this.selectedOption = 0;
        this.modalData = this.modelValue;
        http.fetch("/integrations/" + this.modelValue.id).then((data) => {
          for (const setting of data.appOptions) {
            let ref_id = 0;
            if (
              setting.warehouse_id !== undefined &&
              setting.warehouse_id !== null
            ) {
              ref_id = setting.warehouse_id;
            } else if (
              setting.company_id !== undefined &&
              setting.company_id !== null
            ) {
              ref_id = setting.company_id;
            } else if (
              setting.webshop_id !== undefined &&
              setting.webshop_id !== null
            ) {
              ref_id = setting.webshop_id;
            }
            if (this.appSettings[setting.option_key] === undefined) {
              this.appSettings[setting.option_key] = {};
            }
            this.appSettings[setting.option_key][ref_id] = setting.option_value;
          }

          for (const setting of data.appSettings) {
            if (this.appSettings[setting.option_key] === undefined) {
              this.appSettings[setting.option_key] = {};
            }
          }

          this.integration = data;
        });
        if (this.companies.length === 0) {
          this.selectedWarehouse = this.warehouses[0].id;
          http.fetch("/companies").then((data) => {
            this.companies = data;
            this.selectedCompany = this.companies[0].id;
          });
        }
        if (this.webshops.length === 0) {
          http.fetch("/webshops").then((data) => {
            this.webshops = data;
            this.selectedWebshop = this.webshops[0].id;
          });
        }
      },
    },
  },
  computed: {
    warehouses() {
      return this.store.state.warehouses;
    },
  },
  methods: {
    login() {
      http
        .fetch("/apps/" + this.modelValue.id + "/" + this.modelValue?.auth_url)
        .then((data) => {
          location.href = data.url;
        });
    },
    save() {
      http.fetch(
        "/integrations/" + this.modelValue.id,
        { settings: this.appSettings },
        true
      );
    },
    changeWarehouse(id) {
      this.selectedWarehouse = id;
    },
    changeCompany(id) {
      this.selectedCompany = id;
    },
    changeWebshop(id) {
      this.selectedWebshop = id;
    },
    formatDate(val) {
      return date.format(val, true, false);
    },
    hideModal() {
      this.myModal.hide();
    },
    showModal() {
      this.myModal = new bootstrap.Modal(this.$refs.modal, {
        backdrop: "static",
      });
      this.myModal.show();
    },
  },
};
</script>
